export default {
  Components: {
    input: {
      numbers_only: 'Numbers only',
    },
    filter_select: {
      results_not_found: 'Results not found',
    },
  },
}
