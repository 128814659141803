export default {
  Modal: {
    delete: {
      cant_undo_message: 'esta acción no se puede deshacer',
      field_delete_confirmation: 'está seguro de que desea eliminar este lote?',
      sowing_delete_confirmation:
        'está seguro de que desea eliminar esta siembra?',
    },
  },
}
