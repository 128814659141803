export default {
  Components: {
    input: {
      numbers_only: 'Sólo números',
    },
    filter_select: {
      results_not_found: 'Resultados no encontrados',
    },
  },
}
