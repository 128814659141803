export default {
  Modal: {
    delete: {
      cant_undo_message: 'essa ação não poderá ser desfeita',
      field_delete_confirmation: 'tem certeza que deseja excluir esse talhão?',
      sowing_delete_confirmation:
        'tem certeza que deseja excluir essa semeadura?',
    },
  },
}
