export const localeOptions = [
  {
    name: 'Português',
    value: 'pt',
    flag: 'img/flag-portuguese.png',
  },
  {
    name: 'English',
    value: 'en',
    flag: 'img/flag-english.png',
  },
  {
    name: 'Español',
    value: 'es',
    flag: 'img/flag-spanish.png',
  },
]
