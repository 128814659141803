<template v-bind="$attrs" v-on="$listeners">
  <v-btn
    :class="classes"
    :disabled="disabled"
    :loading="loading"
    :plain="plain"
    :text="variant === 'text'"
    :width="width"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <font-awesome-icon
      v-if="leftIcon"
      :class="[flank, 'mr-2']"
      :icon="leftIcon"
      :style="{ color: colorIcon }"
    />
    <p v-if="show" :style="{ width: widthSlot }" class="text-personalized">
      <slot></slot>
    </p>
    <font-awesome-icon
      v-if="icon"
      :class="[flank, 'ml-2']"
      :icon="icon"
      :style="{ color: colorIcon }"
    />
  </v-btn>
</template>

<script>
export default {
  name: 'DfButton',

  props: {
    colorIcon: {
      type: String,
      default: '',
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    flank: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    leftIcon: {
      type: String,
      default: '',
    },
    loading: {
      default: false,
      type: Boolean,
    },
    plain: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String,
      default: 'primary',
      validator: (value) =>
        ['primary', 'secondary', 'blurred', 'text', 'text-secondary'].includes(
          value
        ),
    },
    width: {
      type: String,
      default: '',
    },
    widthSlot: {
      type: String,
      default: '',
    },
  },

  computed: {
    classes() {
      return {
        'df-button': true,
        'df-button--primary': this.variant === 'primary',
        'df-button--secondary': this.variant === 'secondary',
        'df-button--blurred': this.variant === 'blurred',
        'df-button--text': this.variant === 'text',
        'df-button--text-secondary': this.variant === 'text-secondary',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.df-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 12px !important;
  color: #fff;
  font-weight: bold;
  text-transform: none !important;
  border-radius: 4px;
  box-shadow: none;
  font-family: 'Rubik';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
}
.df-button--primary {
  background-color: #39af49 !important;
}
.df-button--primary:hover {
  background-color: #2b8337 !important;
  box-shadow: none !important;
}
.df-button--secondary {
  background-color: #fff !important;
  border: 1px solid #e6e9e6;
  color: #3f4e3c !important;
}
.df-button--secondary:hover {
  box-shadow: none !important;
}
.df-button--blurred {
  background: rgba(0, 0, 0, 0.4) !important;
  box-shadow: none;
}
.df-button--blurred:hover {
  background: #39af49 !important;
}
.df-button--text {
  color: #1a2b46 !important;
  box-shadow: none !important;
}
.df-button--text:hover {
  color: #4a76bc !important;
}
.df-button--text-secondary {
  background-color: #fff !important;
  box-shadow: none;
  color: #1a2b46 !important;
}
.df-button--text-secondary:hover {
  background-color: #fff !important;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3) !important;
}
.text-personalized {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.v-btn--outlined {
  border: 1px solid #e6e9e6;
}
</style>

<style lang="scss">
.df-button {
  &.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(
      :hover
    )
    .v-btn__content {
    opacity: 1;
  }
}
</style>
