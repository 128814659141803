export default {
  short: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  },
  day_month: {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
  },
  weekday_day_month: {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
  },
  time: {
    hour: 'numeric',
    minute: 'numeric',
  },
  timezone: 'America/Sao_Paulo',
  locale: 'pt-BR',
}
