export default {
  currency: {
    style: 'currency',
    currency: 'BRL',
  },
  decimal: {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
}
