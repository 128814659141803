export default {
  Modal: {
    delete: {
      cant_undo_message: "this action can't be undone",
      field_delete_confirmation: 'are you sure you want to delete this field?',
      sowing_delete_confirmation:
        'are you sure you want to delete this sowing?',
    },
  },
}
