<template>
  <div class="df-flex-sm df-flex-col df-header-title">
    <h2 v-if="subtitle" class="header__subtitle">{{ subtitle }}</h2>
    <h1 class="header__title">{{ title }}</h1>
  </div>
</template>

<script>
export default {
  name: 'DfHeaderTitle',

  props: {
    title: {
      required: true,
      type: String,
    },

    subtitle: {
      default: '',
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
.df-header-title {
  .header__subtitle {
    color: #1a2b46;
    font-family: 'Rubik';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
  .header__title {
    color: #1a2b46;
    font-family: 'Rubik';
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
  }
}
</style>
